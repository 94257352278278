export class AcimaCreditCheckout {
    constructor(settings, customerData, transactionData, thankYouPage, orderId, nonce) {
        this.settings  = settings ;
        this.client = null;
        this.customer = customerData;
        this.transaction = transactionData;
        this.thankYouPage = thankYouPage;
        this.orderId = orderId;
        this.nonce = nonce;
        console.log('AcimaCreditCheckout constructor called with settings:', settings);
    }

    redirectToThankYouPage() {
        if (this.thankYouPage) {
            window.location.href = this.thankYouPage;
        } else {
            console.error('Thank you page URL is not set');
        }
    }

    async initCheckout() {
        try {
            console.log('Initiating checkout with client:', this.client);
            return window.AcimaCredit.client.checkout({
                customer: this.customer,
                transaction: this.transaction,
                isSuccessCallbackMode: true,
                onSuccess: this.success.bind(this),
                onError: this.handleError.bind(this),
                onCheckoutSuccessful: this.handleCheckoutSuccessful.bind(this),
                onPreapprovalSuccessful: this.handlePreapprovalSuccessful.bind(this),
                onPrequalSuccessful: this.handlePrequalSuccessful.bind(this),
                onInitialPayment: this.handleInitialPayment.bind(this),
                onLeaseAgreementSigned: this.handleLeaseAgreementSigned.bind(this),
                onPageTransition: this.handlePageTransition.bind(this),
                onDenied: this.handleDenied.bind(this),
                onCancelled: this.handleCancelled.bind(this),
                onIncomplete: this.handleIncomplete.bind(this),
            }).then(async (response) => {
                console.log("Checkout response:", JSON.stringify(response));
                await this.closeOrder(response);
                response.createOrderComplete();
                this.redirectToThankYouPage();
                return response;
            }).catch((error) => {
                if (error.code === 'CheckoutInterrupted') {
                    console.log(error.message);
                } else {
                    console.error("Unhandled error:", error);
                }
                throw error;
            });
        } catch (error) {
            console.error('Error during checkout initialization:', error);
            throw error;
        }
    }

    success(result) {
        console.log("Success:", result);
        this.saveLeaseInfo(result);
    }

    handleError(error) {
        console.error("Checkout error:", error);
    }

    handleCheckoutSuccessful(result) {
        console.log("onCheckoutSuccessful:", result);
        this.saveLeaseInfo(result);
    }

    handlePreapprovalSuccessful(result) {
        console.log("onPreapprovalSuccessful:", result);
        this.saveLeaseInfo(result);
    }

    handlePrequalSuccessful(result) {
        console.log("onPrequalSuccessful:", result);
        this.saveLeaseInfo(result);
    }

    handleInitialPayment(result) {
        console.log("onInitialPayment:", result);
        this.saveLeaseInfo(result);
    }

    handleLeaseAgreementSigned(result) {
        console.log("onLeaseAgreementSigned:", result);
        this.saveLeaseInfo(result);
    }

    handlePageTransition(result) {
        console.log("onPageTransition:", result);
    }

    handleDenied(result) {
        console.log("onDenied:", result);
        this.saveLeaseInfo(result);
    }

    handleCancelled(result) {
        console.log("onCancelled:", result);
        this.saveLeaseInfo(result);
    }

    handleIncomplete(result) {
        console.log("onIncomplete:", result);
        this.saveLeaseInfo(result);
    }

    handleCheckoutResponse(response) {
        console.log("Checkout response:", JSON.stringify(response));
        this.closeOrder(response);
        response.createOrderComplete();
        this.redirectToThankYouPage();
    }

    handleCheckoutError(error) {
        if (error.code === 'CheckoutInterrupted') {
            console.log(error.message);
        } else {
            console.error("Unhandled error:", error);
        }
    }

    async closeOrder(result) {
        const storedNonce = sessionStorage.getItem('acimaNonce');
        console.log('this.nonce:', this.nonce);
        console.log('Using stored nonce:', storedNonce);
        const data = {
            action: 'acima_credit_checkout_successful',
            order: this.orderId,
            // nonce: this.nonce,
            nonce: storedNonce,
            lease_id: result.leaseId || result.applicationId,
            checkout_token: result.checkoutToken
        };

        console.log('Closing order with data:', data);

        try {
            const ajaxUrl = window.woocommerce_params && window.woocommerce_params.ajax_url
                ? window.woocommerce_params.ajax_url
                : '/wp-admin/admin-ajax.php';

            const response = await fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(data).toString()
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Order closing response:', responseData);

            if (responseData.success) {
                console.log('Order closed successfully');
            } else {
                console.error('Error closing order:', responseData);
                throw new Error('Failed to close order');
            }
        } catch (error) {
            console.error('Error in closeOrder:', error);
            throw error;
        }
    }

    saveLeaseInfo(result) {
        const data = JSON.stringify({
            leaseId: result.leaseId || result.applicationId,
            leaseNumber: result.leaseNumber,
            orderId: this.orderId
        });

        fetch('/?rest_route=/acima/v1/cancel-checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        })
            .then(response => response.json())
            .then(data => console.log('Lease saved successfully:', data))
            .catch(error => console.error('Error saving lease:', error));
    }
}
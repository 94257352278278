import { sprintf, __ } from '@wordpress/i18n';
import { registerPaymentMethod } from '@woocommerce/blocks-registry';
import { decodeEntities } from '@wordpress/html-entities';
import { getSetting } from '@woocommerce/settings';
import { useEffect, useState } from '@wordpress/element';
import { processAcimaPayment } from './block-checkout';

const settings = getSetting('acima_credit_data', {});
const defaultLabel = __('Acima Leasing', 'acima_credit');
const label = decodeEntities(settings.title) || defaultLabel;

const Content = (props) => {
    const { eventRegistration, emitResponse } = props;
    const { onPaymentSetup, onCheckoutAfterProcessingWithError, onCheckoutValidation } = eventRegistration;
    const [checkoutData, setCheckoutData] = useState(null);
    const [orderNonce, setOrderNonce] = useState(null);

    useEffect(() => {
        const fetchCheckoutData = async () => {
            try {
                const response = await fetch('/wp-json/wc/store/v1/checkout', {
                    method: 'GET',
                    headers: {
                        'Nonce': settings.storeApiNonce,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch checkout data');
                }

                const data = await response.json();
                setCheckoutData(data);
                console.log('Checkout data fetched:', data);

                const nonceResponse = await fetch(`/wp-json/wc-acima-credit/v1/generate-order-nonce`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ order_id: data.order_id })
                });

                if (!nonceResponse.ok) {
                    throw new Error('Failed to generate nonce');
                }

                const nonceData = await nonceResponse.json();
                console.log('Received nonce from server:', nonceData.nonce);
                sessionStorage.setItem('acimaNonce', nonceData.nonce);
                setOrderNonce(nonceData.nonce);
            } catch (error) {
                console.error('Acima Credit: Error fetching checkout data:', error);
            }
        };

        fetchCheckoutData();
    }, []);

    useEffect(() => {
        const unsubscribeValidation = onCheckoutValidation(() => {
            console.log('Acima Credit: onCheckoutValidation called');
            return true;
        });

        const unsubscribePaymentSetup = onPaymentSetup(async () => {
            if (!checkoutData) {
                console.log('Acima Credit: Checkout data not available');
                return {
                    type: emitResponse.responseTypes.ERROR,
                    message: 'Checkout data not available',
                };
            }

            if (!sessionStorage.getItem('acimaNonce')) {
                console.log('Acima Credit: Order nonce not available');
                return {
                    type: emitResponse.responseTypes.ERROR,
                    message: 'Order nonce not available',
                };
            }

            try {
                console.log('Acima Credit: Payment setup initiated');

                const result = await processAcimaPayment({
                    customerData: {
                        billingAddress: checkoutData.billing_address,
                        shippingAddress: checkoutData.shipping_address,
                    },
                    transactionData: {
                        orderId: checkoutData.order_id,
                        orderKey: checkoutData.order_key,
                    },
                    orderNonce: orderNonce
                });

                return {
                    type: emitResponse.responseTypes.SUCCESS,
                    meta: {
                        paymentMethodData: {
                            acima_lease_id: result.leaseId,
                        },
                    },
                };
            } catch (error) {
                console.error('Acima Credit: Error processing payment:', error);
                return {
                    type: emitResponse.responseTypes.ERROR,
                    message: error.message || 'An error occurred while processing your payment.',
                };
            }
        });

        const unsubscribeError = onCheckoutAfterProcessingWithError(() => {
            console.log('Acima Credit: Error occurred after processing');
            return { type: emitResponse.responseTypes.ERROR };
        });

        return () => {
            unsubscribeValidation();
            unsubscribePaymentSetup();
            unsubscribeError();
        };
    }, [onCheckoutValidation, checkoutData, orderNonce, onPaymentSetup, onCheckoutAfterProcessingWithError, emitResponse.responseTypes]);

    return decodeEntities(settings.description || '');
};

const Label = (props) => {
    const { PaymentMethodLabel } = props.components;
    return <PaymentMethodLabel text={label} />;
};

const Acima = {
    name: 'acima_credit',
    label: <Label />,
    content: <Content />,
    edit: <Content />,
    canMakePayment: () => true,
    ariaLabel: label,
    supports: {
        features: settings.supports,
    },
};

registerPaymentMethod(Acima);